import './jquery-global';

import $ from 'jquery';

import 'slick-carousel';
import 'fancybox/dist/js/jquery.fancybox';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('#navbar-toggler').addEventListener('click', (event) => {
    event.preventDefault();

    document.body.classList.toggle('mainnav--active');
  });
});

$(() => {
  $('.highlights__element').fancybox({
    helpers: {
      title: {
        type: 'inside',
      },
      overlay: {
        locked: false,
      },
    },
  });

  $('.carousel-container__element').fancybox({
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  // Smooth Scroll
  $('a[href]:not([href^="mailto\\:"], [href$="\\#"])').on('click', function clickFunction() {
    if (
      window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      window.location.hostname === this.hostname
    ) {
      let target = $(this.hash);
      target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
      if (target.length) {
        $('html,body').animate(
          {
            scrollTop: target.offset().top - 120,
          },
          1000
        );
        return false;
      }
    }
    return true;
  });

  $('.responsive').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
});
